import React from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import styles from './Popular.module.scss'
import { motion } from "framer-motion"
import { v4 as uuidv4 } from 'uuid'
import arrow from '../../image/Textile/arrow.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3}
    } 
}


const Popular = ({arr}) => {

    const routes  = useLocation()

        return (
            <div className={styles.box}>
                {routes.pathname === '/'  ? (<motion.h2 className={styles.title} variants={titleAnimation}>Покупают<span> чаще всего</span></motion.h2>) :
                    (<motion.h2 className={styles.title} variants={titleAnimation}>Смотрите <span> также</span></motion.h2>)} 
                <ul className={styles.list}>
                {arr.map((item, index) => {
                        const keyUid = uuidv4()
                            return(
                                <motion.li
                                    key={keyUid}
                                    className={styles.item} 
                                    variants={cardAnimation}
                                    initial='hidden'
                                    whileInView='visible'
                                    viewport={{amount: 0.03, once: true}}
                                >
                                    <Link className={styles.link} to={item.link}>
                                    <div className={styles.cell_box}>
                                        <img className={styles.cell_image} src={item.image} alt='баня'/>
                                    </div>
                                    <div className={styles.cell_info}>
                                        <h3 className={styles.cell_subtitle}>{item.type}</h3>
                                        
                                        <p className={styles.cell_sort}>{item.sort}</p>
                                    </div>
                                    <span className={styles.cell_arrow}>
                                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                                    </span>
                                    </Link>    
                                </motion.li>
                            ) 
                    })}     
                </ul>
            </div>   
        )
    }
    
    export default Popular