import React, {useState, useEffect} from 'react'
import { motion } from "framer-motion"
import styles from './Portfolio.module.scss'
import { v4 as uuidv4 } from 'uuid'
import ScreenSize from '../../hooks/ScreenSize'
import { arrPortfolio } from '../../utils/data'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}


const Portfolio = ({isImageOpen}) => {

    const width = ScreenSize()
    const [moviesTotal, setMoviesTotal] = useState(0)


    useEffect(() => {
        const getCards = () => {
            if (width >= 980) {
                setMoviesTotal(12);
                } else if (width < 980 && width >= 500) {
                    setMoviesTotal(6);
                        } else if (width <= 500) {
                            setMoviesTotal(4);
                            }
        }
        getCards();
    }, [width])

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.2, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Образцы<span> наших принтов</span></motion.h2>
                <motion.ul className={styles.list} variants={boxAnimation}>
                    {arrPortfolio.map((item, index) =>{
                        const keyUid = uuidv4()
                        if (index + 1 <= moviesTotal) {
                            return(
                                <li className={styles.item} key={keyUid}>
                                    <img className={styles.image} src={item.image} alt='принт'/>
                                </li>
                            ) 
                        }else {
                            return ''
                        }
                    })}
                </motion.ul>
                <button  className={styles.button} onClick={isImageOpen}>Смотреть все принты</button>
            </div>
        </motion.section>
    )
}

export default Portfolio

