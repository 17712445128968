import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import styles from '../MuslinTwoLayer/MuslinTwoLayer.module.scss'
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs'
import CarouselPhoto from '../../../components/CarouselPhoto/CarouselPhoto'
import { arrWaffleClothTkan } from '../../../utils/data'
import WidthDensity from '../../../components/WidthDensity/WidthDensity'
import ColorsList from '../../../components/ColorsList/ColorsList'
import Popular from '../../../components/Popular/Popular'
import { arrPopularWaffle } from '../../../utils/data'



const WaffleClothTkan = ({isOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        return (
            <section 
            className={styles.main} 
            >
            <BreadCrumbs
                text={'Вафельное полотно'}
            >
                <p className={styles.menu_text}><span>&ensp;/&ensp;</span></p>
                <Link className={styles.menu_link} to='/tkani'>Ткани</Link>
            </BreadCrumbs>
            <div className={styles.box}>
                <div className={styles.list_image}>
                    <CarouselPhoto
                        arr={arrWaffleClothTkan}
                    />
                </div>
                <div className={styles.box_info}>
                    <div>
                        <h2 className={styles.title}>Вафельное полотно</h2>
                        <p className={styles.subtitle}>тканое/трикотажное полотно</p>
                    </div>
                    <div className={styles.line}></div>
                        <WidthDensity
                            type='ткань'
                            width='240'
                            density='380'
                        />
                        <WidthDensity
                            type='ткань'
                            width='240'
                            density='240'
                        />
                        <div className={styles.line}></div>
                        <ColorsList
                        arr={arrWaffleClothTkan}
                        />
                        <button className={styles.button} type='button' onClick={isOpen}>заказать полотно</button>
                </div>
            </div>
            <Popular
                arr={arrPopularWaffle}
            />    
            </section>
        )
    }
    
    export default WaffleClothTkan