import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import styles from '../MuslinTwoLayer/MuslinTwoLayer.module.scss'
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs'
import CarouselPhoto from '../../../components/CarouselPhoto/CarouselPhoto'
import { arrFlannel } from '../../../utils/data'
import WidthDensity from '../../../components/WidthDensity/WidthDensity'
import ColorsList from '../../../components/ColorsList/ColorsList'
import Popular from '../../../components/Popular/Popular'
import { arrPopular } from '../../../utils/data'



const Flannel = ({isOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        return (
            <section 
            className={styles.main} 
            >
            <BreadCrumbs
                text={'Фланель'}
            >
                <p className={styles.menu_text}><span>&ensp;/&ensp;</span></p>
                <Link className={styles.menu_link} to='/tkani'>Ткани в наличии</Link>
            </BreadCrumbs>
            <div className={styles.box}>
                <div className={styles.list_image}>
                    <CarouselPhoto
                        arr={arrFlannel}
                    />
                </div>
                <div className={styles.box_info}>
                    <div>
                        <h2 className={styles.title}>Фланель</h2>
                        <p className={styles.subtitle}>трикотажное полотно</p>
                    </div>
                    <div className={styles.line}></div>
                        <WidthDensity
                            type='фланель'
                            width='240'
                            density='160'
                        />
                        <div className={styles.line}></div>
                        <ColorsList
                        arr={arrFlannel}
                        />
                        <button className={styles.button} type='button' onClick={isOpen}>заказать Фланель</button>
                </div>
            </div>
            <Popular
                arr={arrPopular}
            />     
            </section>
        )
    }
    
    export default Flannel