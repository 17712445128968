import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import styles from '../MuslinTwoLayer/MuslinTwoLayer.module.scss'
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs'
import CarouselPhoto from '../../../components/CarouselPhoto/CarouselPhoto'
import { arrKulirka } from '../../../utils/data'
import WidthDensity from '../../../components/WidthDensity/WidthDensity'
import ColorsList from '../../../components/ColorsList/ColorsList'
import Popular from '../../../components/Popular/Popular'
import { arrPopular } from '../../../utils/data'



const Kulirka = ({isOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        return (
            <section 
            className={styles.main} 
            >
            <BreadCrumbs
                text={'Кулирка'}
            >
                <p className={styles.menu_text}><span>&ensp;/&ensp;</span></p>
                <Link className={styles.menu_link} to='/tkani'>Ткани в наличии</Link>
            </BreadCrumbs>
            <div className={styles.box}>
                <div className={styles.list_image}>
                    <CarouselPhoto
                        arr={arrKulirka}
                    />
                </div>
                <div className={styles.box_info}>
                    <div>
                        <h2 className={styles.title}>Кулирка</h2>
                        <p className={styles.subtitle}>трикотажное полотно</p>
                    </div>
                    <div className={styles.line}></div>
                        <WidthDensity
                            type='с&#160;лайкрой'
                            width='180'
                            density='160-170'
                        />
                        <WidthDensity
                            type='с&#160;лайкрой'
                            width='180'
                            density='210-220'
                        />
                        <div className={styles.line}></div>
                        <ColorsList
                        arr={arrKulirka}
                        />
                        <button className={styles.button} type='button' onClick={isOpen}>заказать Кулирку</button>
                </div>
            </div>
            <Popular
                arr={arrPopular}
            />      
            </section>
        )
    }
    
    export default Kulirka