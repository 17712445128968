import React from 'react'
import { motion } from "framer-motion"
import styles from './Print.module.scss'
import image from '../../image/Print/img_2.png'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const Print = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.box}>
                <div className={styles.container}>
                    <motion.img className={styles.image} src={image} alt='люди' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title} variants={titleAnimation}>СТОЛЬКО, <span>СКОЛЬКО НУЖНО</span></h2>
                        <p className={styles.text}>&#8195;Цифровой способ печати позволяет производить ткань и трикотаж очень малыми тиражами на один дизайн, что делает изделия эксклюзивными и помогает покупателю подчеркнуть свою уникальность.</p>
                        <p className={styles.text}>В отличие от аналоговой печати у нас не нужно заказывать 100 м на 1 принт. Обращаясь в компанию TeksPrint Вы можете заказать от 1 до 10 дизайнов на 100 м .</p>
                        <p className={styles.text}>Заказывая цифровую печать Вы можете прислать свои уникальные принты или выбрать подходящие из широкого ассортимента актуальных принтов библиотеки производства.</p>
                        <p className={styles.text}>Вы можете выбрать основы для печати из нашего каталога и аказать образец печати на интересующей вас ткани.</p>
                    </motion.div>
                </div>
            </div>
        </motion.section>
    )
}

export default Print