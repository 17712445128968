import React, { useState, useCallback, useEffect} from 'react'
import { Route, Routes} from 'react-router-dom'
import app from './App.module.scss'
import { AppHeader } from '../AppHeader/AppHeader'
import Main  from '../../pages/Main/Main'
import { ModalForm } from '../ModalForm/ModalForm'
import UpButton  from '../UpButton/UpButton'
import { MenuMobile } from '../MenuMobile/MenuMobile'
import { PageNotFound } from '../../pages/PageNotFound/PageNotFound'
import { PrivacyPolicy } from '../../pages/PrivacyPolicy/PrivacyPolicy'
import Footer  from '../Footer/Footer'
import Contacts  from '../../pages/Contacts/Contacts'
import Modal from '../Modal/Modal'
import ButtonCommunication from '../ButtonCommunication/ButtonCommunication'
import { ModalImage } from '../ModalImage/ModalImage'
import Certificates from '../../pages/Certificates/Certificates'
import Textile from '../../pages/Textile/Textile'
import DigitalPrinting from '../../pages/DigitalPrinting/DigitalPrinting'
import { arrPortfolio } from '../../utils/data'

////
import MuslinTwoLayer from '../../pages/TextilePages/MuslinTwoLayer/MuslinTwoLayer'
import MuslinFourLayer from '../../pages/TextilePages/MuslinFourLayer/MuslinFourLayer'
import Satin from '../../pages/TextilePages/Satin/Satin'
import Poplin from '../../pages/TextilePages/Poplin/Poplin'
import WaffleCloth from '../../pages/TextilePages/WaffleCloth/WaffleCloth'
import Velour from '../../pages/TextilePages/Velour/Velour'
import Mahra from '../../pages/TextilePages/Mahra/Mahra'
import Kulirka from '../../pages/TextilePages/Kulirka/Kulirka'
import Interlock from '../../pages/TextilePages/Interlock/Interlock'
import Futer from '../../pages/TextilePages/Futer/Futer'
import Kashkorse from '../../pages/TextilePages/Kashkorse/Kashkorse'
import Pasta from '../../pages/TextilePages/Pasta/Pasta'
import Flannel from '../../pages/TextilePages/Flannel/Flannel'
import WaffleClothTkan from '../../pages/TextilePages/WaffleClothTkan/WaffleClothTkan'


export const App = () =>{

  const [isMenuMobile, setIsMenuMobile] = useState(false)
  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPopupImageOpen, setIsPopupImageOpen] = useState(false)
  const [isArr, setIsArr] = useState(null)

useEffect(() => {
  window.scrollTo(0, 0)
}, [])

  const handleCloseMenuMobile = useCallback(() => {
    setIsMenuMobile(false)
  }, []);

  const handleOpenMenuMobile = useCallback(() => {
    setIsMenuMobile(true)
  }, []);

  const handleFormOpen = useCallback(() => {
    setIsPopupFormOpen(true)
  }, []);

  const handleFormClose = useCallback(() => {
    setIsPopupFormOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setIsPopupOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, []);

  const handleImageClose = useCallback(() => {
    setIsPopupImageOpen(false)
  }, []);

  const handleImageOpen = useCallback(() => {
      setIsPopupImageOpen(true)
      setIsArr(arrPortfolio)
  }, [])


  return (
    <div className={app.page}>
    <AppHeader
      isOpen={handleOpenMenuMobile}
    />
      <Routes> 
        <Route  path='/'  element={
          <Main
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/tkani'  element={
          <Textile
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/cifrovaya-pechat'  element={
          <DigitalPrinting
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
            isImageOpen={handleImageOpen}
          />
        }/>
        <Route  path='/sertifikaty'  element={
          <Certificates
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/kontakty'  element={
          <Contacts
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/muslin-dvuhslojnyj'  element={
          <MuslinTwoLayer
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/muslin-chetyrekhslojnyj'  element={
          <MuslinFourLayer
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/satin'  element={
          <Satin
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/poplin'  element={
          <Poplin
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/vafelnoe-polotno'  element={
          <WaffleCloth
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/vafelnaa-tkan'  element={
          <WaffleClothTkan
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/velyur'  element={
          <Velour
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/mahra'  element={
          <Mahra
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/kulirka'  element={
          <Kulirka
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/interlok'  element={
          <Interlock
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/futer'  element={
          <Futer
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/kashkorse'  element={
          <Kashkorse
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/lapsha'  element={
          <Pasta
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/flanel'  element={
          <Flannel
            isOpen={handleFormOpen}
          />
        }/>
        <Route path='*' element={
          <PageNotFound/>
                  }/>
        <Route  path='/privacy-policy'  element={
          <PrivacyPolicy/>
        }/>         
      </Routes>
      <Footer/>
      <MenuMobile
        onClose={handleCloseMenuMobile}
        isMenuMobile={isMenuMobile}
      />
      {isPopupFormOpen && <ModalForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></ModalForm>}
      {isPopupOpen && 
      <Modal
        onClose={handleClose}
      />}
      {isPopupImageOpen && 
      <ModalImage
        onClose={handleImageClose}
        arr={isArr}
      />}
      <UpButton/>
      <ButtonCommunication/>
    </div>
  )
}