import React from 'react'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'
import image from '../../image/Print/img.jpg'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const AboutUs = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.box}>
                <div className={styles.container}>
                    <motion.img className={styles.image} src={image} alt='люди' variants={imageAnimation}/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title} variants={titleAnimation}>Группа компаний <span>PERKY&#160;MOTH</span></h2>
                        <p className={styles.text}>&#8195;Мы рады приветствовать Вас на сайте представительства турецкой группы компаний PERKY&#160;MOTH & TeksPrint в г.Москва.</p>
                        <p className={styles.text}>Компания PERKY&#160;MOTH является крупным производителем высококачественных тканей из натурального сырья. В нашем шоуруме г.Москва Вы всегда можете ознакомиться с широким ассортиментом и преобрести двухслойный и четырехслойный муслин, сатин, поплин, вафельное полотно, велюр, махру, кулирку, интерлок, кашкорсе, футер, лапшу и фланель.</p>
                        <p className={styles.text}>Компания TeksPrint осуществляет цифровую органическую печать на натуральных тканях. У нас Вы можете заказать хлопковую ткань со своим принтом или принтом из нашей коллекции. Так же можете заказать образец печати на интересующей Вас ткани.</p>
                    </motion.div>
                </div>
            </div>
        </motion.section>
    )
}

export default AboutUs