import React from 'react'
import styles from './ColorsList.module.scss'
import { v4 as uuidv4 } from 'uuid'



const ColorsList = ({arr}) => {

        return (
            <ul className={styles.list}>
                { arr.map((item, index) => {
                    const keyUid = uuidv4()
                        return(
                            <li
                                className={styles.item}
                                key={keyUid}
                            >
                                <img className={styles.icon} src={item.image} alt = {item.title}/>
                            </li>
                            ) 
                        })}
                    </ul>
    
        )
    }
    
    export default ColorsList