import React, {useEffect} from 'react'
import styles from './DigitalPrinting.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import Print from '../../components/Print/Print'
import PhoneCoffee from '../../components/PhoneCoffee/PhoneCoffee'
import Confidence from '../../components/Confidence/Confidence'
import Stability from '../../components/Stability/Stability'
import Portfolio from '../../components/Portfolio/Portfolio'



const DigitalPrinting = ({popupOpen, isOpen, isImageOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        return (
            <section 
            className={styles.main} 
            >
            <BreadCrumbs
                text={'Цифровая печать'}
            />
            <Print/>
            <PhoneCoffee
                isOpen={isOpen}
            />
            <Confidence/>
            <Stability
                isOpen={isOpen}
            />
            <Portfolio
                isImageOpen={isImageOpen}
            />
            <Callback
                popupOpen={popupOpen}
            />
            </section>
        )
    }
    
    export default DigitalPrinting