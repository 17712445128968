import React, {useEffect} from 'react'
import styles from './Certificates.module.scss'
import { motion } from "framer-motion"
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import image_1 from '../../image/Certificates/img_1.webp'
import image_2 from '../../image/Certificates/img_2.webp'
import image_3 from '../../image/Certificates/img_3.webp'
import image_4 from '../../image/Certificates/img_4.webp'
import image_5 from '../../image/Certificates/img_5.webp'
import image_6 from '../../image/Certificates/img_6.webp'
import image_7 from '../../image/Certificates/img_7.webp'
import image_8 from '../../image/Certificates/img_8.webp'
import image_9 from '../../image/Certificates/img_9.webp'
import image_10 from '../../image/Certificates/img_10.webp'
import image_11 from '../../image/Certificates/img_11.webp'
import image_12 from '../../image/Certificates/img_12.webp'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Certificates = ({popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        return (
            <section 
            className={styles.main} 
            >
            <BreadCrumbs
                text={'Сертификаты'}
            />
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Наши <span> сертификаты</span></motion.h2>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.image} src={image_1} alt='документы'/>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.image} src={image_2} alt='документы'/>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.image} src={image_3} alt='документы'/>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.image} src={image_4} alt='документы'/>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.image} src={image_5} alt='документы'/>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.image} src={image_6} alt='документы'/>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.image} src={image_7} alt='документы'/>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.image} src={image_8} alt='документы'/>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.image} src={image_9} alt='документы'/>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.image} src={image_10} alt='документы'/>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.image} src={image_11} alt='документы'/>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.image} src={image_12} alt='документы'/>
                    </motion.li>
                </ul>
            </div>
            <Callback
                popupOpen={popupOpen}
            />
            </section>
        )
    }
    
    export default Certificates