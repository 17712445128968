import React from 'react'
import { Link } from 'react-router-dom'
import styles from './MenuMobile.module.scss'
import logo from '../../image/Header/logo.png'
import ButtonsFour from '../../components/ButtonsFour/ButtonsFour'

export const MenuMobile = ({onClose, isMenuMobile}) => {

    return (
        <div className={`${isMenuMobile === true ? styles.main : styles.main_open}`}>
            <div className={styles.box}>
                <img className={styles.logo} src={logo} alt = 'логотип'/>
                <button className={styles.button} onClick={onClose} aria-label='закрыть меню'/>
            </div>
            <nav className={styles.nav}>
                <Link className={styles.menu_link} to="/" onClick={onClose}>Главная</Link>
                <Link className={styles.menu_link} to="/tkani" onClick={onClose}>Ткани</Link>
                <Link className={styles.menu_link} to="/cifrovaya-pechat" onClick={onClose}>Цифровая печать</Link>
                <Link className={styles.menu_link} to="/sertifikaty" onClick={onClose}>Сертификаты</Link>
                <Link className={styles.menu_link} to="/kontakty" onClick={onClose}>Контакты</Link>
                </nav>
                <ButtonsFour/>
        </div>
    );
}