import React, { useState} from 'react'
import { Link } from 'react-router-dom'
import header from './header.module.scss'
import logo from '../../image/Header/logo.png'
import ButtonsBox from '../../components/ButtonBox/ButtonBox'

export const AppHeader = ({isOpen}) => {

    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 10){
            setVisible(true)
        } 
        else if (scrolled <= 10){
            setVisible(false)
        }
    };

    window.addEventListener('scroll', toggleVisible);


    return (
    <header className={`${header.header} ${visible && header.header_scroll}`}>
        <div className={header.box}>
            <Link className={header.logo_link} to='/'>
                <img className={header.logo} src={logo} alt = 'логотип'/>
            </Link>
            <nav className={header.nav}>
                <Link className={header.link} to="/">Главная</Link>
                <Link className={header.link} to="/tkani">Ткани</Link>
                <Link className={header.link} to="/cifrovaya-pechat">Цифровая печать</Link>
                <Link className={header.link} to="/sertifikaty">Сертификаты</Link>
                <Link className={header.link} to="/kontakty">Контакты</Link>
            </nav>
            <div className={header.contacts}>
                <div className={header.box_button}>
                    <ButtonsBox/>
                </div>
                <a className={header.phone}   href="tel:+79015417979" target='_blank' rel='noopener noreferrer'>+7-901-541-79-79</a>
            </div>
            <button className={header.button} type='button' onClick={isOpen} aria-label='Открыть меню'/>
        </div>
    </header>
    );
};