import React from 'react'
import { motion } from "framer-motion"
import styles from './PhoneCoffee.module.scss'
import image from '../../image/Print/bg-commerce.png'

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}

const PhoneCoffee = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box} variants={boxAnimation}>
                <div className={styles.box_text}>
                    <h2 className={styles.title}>Гипоаллергенные красители и забота о&#160;здоровье</h2>
                    <p className={styles.text}>В процессе печати используются красители GOTS стандарта. Это самый известный в мире экологический стандарт производства, который гарантирует органический состав, отсутствие токсичных веществ и тяжелых металлов. Ткани и трикотаж, запечатанные красками, которые использует PERKY MOTH, рекомендованы для отшива готовых изделий для малышей.</p>
                    <button className={styles.button} type='button' onClick={isOpen}>Оставьте заявку</button>
                </div>
                <img className={styles.image} src={image} alt='кофе'/>
            </motion.div>
        </motion.section>
    )
}

export default PhoneCoffee