import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './Offers.module.scss'
import img_1 from '../../image/Offers/img.png'
import img_2 from '../../image/Offers/img_3.png'

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5}
    } 
}


const Offers = () => {

    return (
        <motion.section 
        className={styles.main}
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        variants={titleAnimation}
        >
        <ul className={styles.list}>
            <motion.li 
                className={styles.list_item_one} 
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}} 
                >
                <Link className={styles.item_box} to="/tkani">
                        <div className={styles.box_text}>
                            <h3 className={styles.item_title}>НАТУРАЛЬНЫЕ ТКАНИ ОТ ПРОИЗВОДИТЕЛЯ</h3>
                            <p className={styles.text} >посмотрите наш каталог</p>
                        </div>
                    <img className={styles.item_image} src={img_2} alt='дизайн'/>
                </Link>
            </motion.li>
            <motion.li 
                className={styles.list_item} 
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}} 
                >
                <Link className={styles.item_box}  to="/cifrovaya-pechat">
                        <div className={styles.box_text}>
                            <h3 className={styles.item_title}>ЦИФРОВАЯ ОРГАНИЧЕСКАЯ ПЕЧАТЬ</h3>
                            <p className={styles.text} >узнайте подробнее</p>
                        </div>
                        <img className={styles.item_image} src={img_2} alt='дизайн'/>
                </Link>
            </motion.li>
        </ul>        
        </motion.section>
    )
}

export default Offers