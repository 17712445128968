import React from 'react'
import styles from './WidthDensity.module.scss'




const WidthDensity = ({width, density, type}) => {
        return (
            <div className={styles.box}>
                <p className={styles.type}>{type}</p>
                <div className={styles.box_info}>
                    <p className={styles.subtitle}>ширина:</p>
                    <p className={styles.text}>{width}см</p>
                </div>
                <div className={styles.box_info}>
                    <p className={styles.subtitle}>плотность:</p>
                    <p className={styles.text}>{density}gr</p>
                </div>
            </div>
        )
    }
    
    export default WidthDensity