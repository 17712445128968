import React from 'react'
import styles from './InitialPage.module.scss'


const InitialPage = ({isOpen}) => {
    return (
        <section className={styles.main} id='initial'>
            <div className={styles.overlay}>
                <div className={styles.container}>
                    <h1 className={styles.title}>НАТУРАЛЬНЫЕ ТКАНИ ИЗ ТУРЦИИ, ЦИФРОВАЯ ОРГАНИЧЕСКАЯ ПЕЧАТЬ</h1>
                    <ul className={styles.list}>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Широкий ассортимент тканей из Турции по ценам производителя</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>В процессе печати используются красители GOTS стандарта</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Работаем без выходных, постоянно принимая и обрабатывая заказы</p>
                        </li>
                    </ul>
                    <div className={styles.box_button}>
                        <button className={styles.button} type='button' onClick={isOpen}>получить прайс</button>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default InitialPage