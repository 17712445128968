import React from 'react'
import { motion } from "framer-motion"
import styles from './Stability.module.scss'
import img from '../../image/Offers/img_3.png'

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}

const Stability = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box} variants={boxAnimation}>
            <img className={styles.image} src={img} alt='кофе'/> 
                <div className={styles.box_text}>
                    <h2 className={styles.title}>Устойчивость к пилингу и яркость изображения</h2>
                    <p className={styles.text}>После нанесения принта на ткань обязательный этапом является нанесение устойчивого малосмываемого аппрета, который придает ткани устойчивость к пиллингу, к сминаемости и массу других полезных свойств. Самое главное - это закрепление цвета, который останется ярким даже после множественных стирок.</p>
                    <button className={styles.button} type='button' onClick={isOpen}>Оставьте заявку</button>
                </div>
            </motion.div>
        </motion.section>
    )
}

export default Stability