import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import styles from './Textile.module.scss'
import { motion } from "framer-motion"
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import { v4 as uuidv4 } from 'uuid'
import { arrTextile } from '../../utils/data'
import arrow from '../../image/Textile/arrow.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


const Textile = ({popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        return (
            <section 
            className={styles.main} 
            >
            <BreadCrumbs
                text={'Ткани в наличии'}
            />
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Каталог <span> наших тканей</span></motion.h2>
                <ul className={styles.list}>
                {arrTextile.map((item, index) => {
                        const keyUid = uuidv4()
                            return(
                                <motion.li
                                    key={keyUid}
                                    className={styles.item} 
                                    variants={cardAnimation}
                                    initial='hidden'
                                    whileInView='visible'
                                    viewport={{amount: 0.01, once: true}}
                                >
                                    <Link className={styles.link} to={item.link}>
                                    <div className={styles.cell_box}>
                                        <img className={styles.cell_image} src={item.image} alt='баня'/>
                                    </div>
                                    <div className={styles.cell_info}>
                                        <h3 className={styles.cell_subtitle}>{item.type}</h3>
                                        
                                        <p className={styles.cell_sort}>{item.sort}</p>
                                    </div>
                                    <span className={styles.cell_arrow}>
                                        <img className={styles.arrow} src={arrow} alt='стрелочка'/>
                                    </span>
                                    </Link>    
                                </motion.li>
                            ) 
                    })}     
                </ul>
            </div>   
            <Callback
                popupOpen={popupOpen}
            />
            </section>
        )
    }
    
    export default Textile