import React from 'react'
import styles from './Confidence.module.scss'
import { motion } from "framer-motion"


const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}



const Confidence = () => {

    return (
        <motion.section 
            className={styles.main}
            id='Advantages'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Почему заказчики <span> выбирают  нас?</span></motion.h2>
                <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>01</p>
                            <p className={styles.item_title}>Собственное производство</p>
                        </div>
                        <p className={styles.item_text}>Мы не посредники – мы производители. <span>Обращаясь к нам напрямую, Вы получаете самую низкую цену, избегая услуг посредников.</span></p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>02</p>
                            <p className={styles.item_title}>Гарантия качества</p>
                        </div>
                        <p className={styles.item_text}>Мы осуществляем контроль качества на каждом из этапов.<span> Поэтому гарантируем качество наших тканей и цифровой печати.</span></p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>03</p>
                            <p className={styles.item_title}>Гипераллергенные красители</p>
                        </div>
                        <p className={styles.item_text}>В процессе печати используются красители GOTS стандарта. Это самый известный в мире экологический стандарт производства, который <span> гарантирует органический состав, отсутствие токсичных веществ и тяжелых металлов.</span></p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>04</p>
                            <p className={styles.item_title}>Цифровая печать</p>
                        </div>
                        <p className={styles.item_text}>Цифровой способ печати позволяет производить ткань и трикотаж очень малыми тиражами на один дизайн, <span>что делает изделия эксклюзивными и помогает покупателю подчеркнуть свою уникальность.</span></p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>05</p>
                            <p className={styles.item_title}>Комплексный подход</p>
                        </div>
                        <p className={styles.item_text}>Производим широкий ассортимент тканей и цифровую печать на них. Это позволяет нашим клиентам заказать все необходимое и <span>существенно сэкономить свое время и сократить расходы.</span> </p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>06</p>
                            <p className={styles.item_title}>Оперативность</p>
                        </div>
                        <p className={styles.item_text}><span>Мы работаем без выходных, постоянно принимая и обрабатывая заказы.</span> Поэтому все заказы наших клиентов обрабатываются и отправляются в максимально сжатые сроки.</p>
                    </motion.li>
                </motion.ul>
            </div>
        </motion.section>
    )
}

export default Confidence