import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import styles from '../MuslinTwoLayer/MuslinTwoLayer.module.scss'
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs'
import CarouselPhoto from '../../../components/CarouselPhoto/CarouselPhoto'
import { arrMuslinFour } from '../../../utils/data'
import WidthDensity from '../../../components/WidthDensity/WidthDensity'
import ColorsList from '../../../components/ColorsList/ColorsList'
import Popular from '../../../components/Popular/Popular'
import { arrPopularMuslinPlus } from '../../../utils/data'


const MuslinFourLayer = ({isOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        return (
            <section 
            className={styles.main} 
            >
            <BreadCrumbs
                text={'Муслин'}
            >
                <p className={styles.menu_text}><span>&ensp;/&ensp;</span></p>
                <Link className={styles.menu_link} to='/tkani'>Ткани в наличии</Link>
            </BreadCrumbs>
            <div className={styles.box}>
                <div className={styles.list_image}>
                    <CarouselPhoto
                        arr={arrMuslinFour}
                    />
                </div>
                <div className={styles.box_info}>
                    <div>
                        <h2 className={styles.title}>Муслин четырехслойный</h2>
                        <p className={styles.subtitle}>тканое полотно</p>
                    </div>
                    <div className={styles.line}></div>
                        <WidthDensity
                            type='жатый'
                            width='155'
                            density='220'
                        />
                        <WidthDensity
                            type='жатый'
                            width='280'
                            density='220'
                        />
                        <WidthDensity
                            type='жаккард'
                            width='266'
                            density='220'
                        />
                    <div className={styles.line}></div>
                    <ColorsList
                        arr={arrMuslinFour}
                    />
                    <button className={styles.button} type='button' onClick={isOpen}>заказать Муслин</button>    
                </div>
            </div>
            <div className={styles.description}>
                <h3 className={styles.description_title}>Преимущества <span>муслина</span></h3>
                <p className={styles.description_text}>&#8195;Муслин - это идеальный выбор для тех, кто ценит комфорт и качество в одежде. Его нежная текстура и хорошая воздухопроницаемость делают его идеальным выбором для горячего летнего дня. Благодаря своей прочности и долговечности, изделия из муслина станут надежным и стильным дополнением к вашему гардеробу.</p>
                <p className={styles.description_text}>Также стоит упомянуть, что муслин отлично справляется с влагой и быстро сохнет, что делает его идеальным материалом для пляжной одежды и аксессуаров. Кроме того, материя легкая и мягкая, что делает ее идеальным выбором для детской одежды - она не сковывает движения и приятна на ощупь.</p>
                <p className={styles.description_text}>Важно отметить, что муслин предлагает широкий выбор цветов и фактур, что делает его универсальным материалом для любого стиля и сезона. Его можно сочетать с различными тканями и аксессуарами, создавая уникальные и стильные образы. В итоге, муслиновая одежда станет незаменимым элементом вашего гардероба, который будет радовать вас своей красотой и комфортом на протяжении многих лет. Ткать Муслин - это также отличный материал для постельного белья. Его нежность и легкость создают идеальные условия для спокойного и комфортного сна. Благодаря своей воздухопроницаемости, муслин обеспечивает хорошую циркуляцию воздуха, что делает его особенно приятным для использования в теплые летние ночи.</p>
                <p className={styles.description_text}>Кроме того, хлопковая ткань муслин часто используется для изготовления пеленок для младенцев. Его мягкость и нежность делают его идеальным выбором для деликатной детской кожи. Благодаря своей прочности, ткань муслин пеленки легко стираются и сохраняют первоначальный вид даже после многократных стирок.</p>
                <p className={styles.description_text}>Не стоит забывать о том, что муслиновые изделия могут быть украшены различными узорами и вышивкой, что делает их еще более привлекательными. Этот материал подходит как для повседневной носки, так и для особых случаев, добавляя изысканности и шарма вашему образу. В итоге, муслин остается популярным выбором среди тех, кто ценит качество, удобство и стиль в одежде и предметах интерьера.</p>
            </div>
            <Popular
                arr={arrPopularMuslinPlus}
            />      
            </section>
        )
    }
    
    export default MuslinFourLayer